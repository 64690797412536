import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Layout } from '../component/Layout'
import { PropertyList } from '../component/page_component/PropertyList'
import { PageProps } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import { parseQueryString } from '../utils/helper'
import { DESTINATIONS } from '../utils/constants'
import DestinationHeader from '../component/page_component/DestinationHeader'

parseQueryString
const Buy: React.FC<PageProps> = (props: any) => {
  const {
    location,
    pageContext: { data },
  } = props
  const region = location?.pathname.split('/')[2]
  const intl = useIntl()

  let destinationHeaderProps = {
    src: '',
    alt: '',
    title: '',
    detail: '',
    btnTxt: '',
    tags: '',
  }

  if (DESTINATIONS[region]) {
    destinationHeaderProps = {
      src: DESTINATIONS[region].img,
      alt: DESTINATIONS[region].alt,
      title: intl.formatMessage({
        id: `dreamDestination.${DESTINATIONS[region].translation}.title`,
      }),
      detail: intl.formatMessage({
        id: `dreamDestination.${DESTINATIONS[region].translation}.detail`,
      }),
      btnTxt: intl.formatMessage({ id: DESTINATIONS[region].btnTxt }),
      tags: intl.formatMessage({
        id: `dreamDestination.${DESTINATIONS[region].translation}.subtitle`,
      }),
    }
  }

  return (
    <Layout location={location} showFlotingMenu={false} title={destinationHeaderProps.title + ' - Kretz'}>
      <DestinationHeader {...destinationHeaderProps} />
      <Grid container justifyContent="center" id="buy-region-dream-destination">
        <PropertyList
          bgClr="#E0E5DE"
          filterBackgroundColor={'#fff'}
          data={data}
          location={location}
        />
      </Grid>
    </Layout>
  )
}

export default Buy
